var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "settlement-view" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "400px",
          display: "flex",
          "justify-content": "center",
          "flex-wrap": "wrap",
        },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              width: "100%",
              "padding-bottom": "16px",
            },
          },
          [
            _vm.status == "success" || _vm.status == "finished"
              ? _c("a-icon", {
                  staticStyle: { color: "#5389DF", "font-size": "100px" },
                  attrs: { type: "check-circle", theme: "filled" },
                })
              : _c("a-icon", {
                  staticStyle: { color: "red", "font-size": "100px" },
                  attrs: { type: "close-circle", theme: "filled" },
                }),
            _c("div", { staticStyle: { "padding-top": "20px" } }, [
              _vm.status == "success"
                ? _c(
                    "span",
                    { staticStyle: { "font-size": "22px", color: "#5389DF" } },
                    [_vm._v("确认成功")]
                  )
                : _vm.status == "finished"
                ? _c(
                    "span",
                    { staticStyle: { "font-size": "22px", color: "#5389DF" } },
                    [_vm._v("已确认，请勿重复点击")]
                  )
                : _c(
                    "span",
                    { staticStyle: { "font-size": "22px", color: "red" } },
                    [_vm._v("确认失败，请重试")]
                  ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "border-top": "1px solid rgba(0,0,0,0.1)",
              display: "flex",
              "justify-content": "space-between",
              width: "100%",
              padding: "16px 12px",
              "font-size": "20px",
            },
          },
          [
            _c("span", [_vm._v("确认金额")]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.totalPrice) + _vm._s(_vm.moneyTypeMap[_vm.priceUnit])
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }