<template>
  <div class="settlement-view">
    <div style="width: 400px;  display: flex;justify-content: center;flex-wrap:wrap">
      <div style="text-align:center;width:100%;padding-bottom:16px;">
        <a-icon v-if="status=='success'||status=='finished'" style="color:#5389DF;font-size:100px" type="check-circle" theme="filled" />
        <a-icon v-else style="color:red;font-size:100px" type="close-circle" theme="filled" />
        <div style="padding-top:20px">
          <span v-if="status=='success'" style="font-size:22px;color:#5389DF;">确认成功</span>
          <span v-else-if="status=='finished'" style="font-size:22px;color:#5389DF;">已确认，请勿重复点击</span>
          <span v-else style="font-size:22px;color:red;">确认失败，请重试</span>
        </div>
      </div>
      <!-- 陈立说的确认失败有金额 -->
      <div
        style="border-top:1px solid rgba(0,0,0,0.1);display: flex;justify-content: space-between;width:100%;padding:16px 12px;font-size:20px"
      >
        <span>确认金额</span>
        <span>{{totalPrice}}{{moneyTypeMap[priceUnit]}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moneyTypeMap: {
        CNY: '元',
        TWD: '台币',
        JPY: '円',
        USD: '美元'
      },
      status:'failed',
      totalPrice:0,
      priceUnit:'CNY'
    }
  },
  created(){
    let arr = window.location.search.split('?')[1].split('&')
    arr.forEach(item=>{
      if(item.indexOf('status')>-1){
        this.status = item.replaceAll('status=','')
      }
      if(item.indexOf('totalPrice')>-1){
        this.totalPrice = item.replaceAll('totalPrice=','')
      }
      if(item.indexOf('priceUnit')>-1){
        this.priceUnit = item.replaceAll('priceUnit=','')
      }
    })
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.settlement-view {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settlement-table {
  /deep/.ant-table-thead > tr > th {
    border-top: 2px solid black !important;
    border-left: 2px solid black !important;
    border-bottom: 2px solid black !important;
  }
  /deep/.ant-table-thead > tr > th:last-of-type {
    border-right: 2px solid black !important;
  }
  /deep/.ant-table-tbody > tr > td {
    border-bottom: 2px solid black !important;
    border-left: 2px solid black !important;
  }
  /deep/.ant-table-tbody > tr > td:last-of-type {
    border-right: 2px solid black !important;
  }
  /deep/ .ant-table-tbody > tr:hover > td {
    background: transparent !important;
  }
}
</style>
